@import 'src/styles/base/color';

.modal-empty-value-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0.5rem;
  text-align: center;
  .modal-empty-value-title {
    margin: 2rem 0 1rem;
  }

  button.btn-secondary-negative {
    margin: 2rem 0.25rem 1.5rem;
  }
}

#accessibility-title {
  display: none;
}
