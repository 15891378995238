@import '../base/color';
@import '../base/z-index';

div.modal-root {
  .MuiBackdrop-root {
    background-color: hsla(231, 11%, 12%, 0.85);
  }
}

div.modal-paper {
  background: $grey-linear-gradient-background;
  width: 36rem;
  max-width: 100%;
  max-height: 90vh;
  padding: 2rem;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.55);
  border-radius: 4px;
  align-items: center;
  color: $white;
  @media #{$tablet} {
    width: 35rem;
    margin: 0;
  }
  @media #{$large-phone} {
    padding: 1rem 1rem 1.5rem;
    width: 85%;
    max-width: 35rem;
    margin: 0;
  }
  &.no-padding {
    padding: 0;
  }
  &.blue-border {
    border: 1px solid $blue-40;
  }
  &.blue-light-border {
    border: 1px solid $blue-light;
  }
  &.yellow-border {
    border: 1px solid $gold-40;
  }
  .modal-paper-close-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    padding: 5px 5px;
    z-index: $z-dialog;
  }
}

div.modal-paper-full-screen {
  background: $grey-linear-gradient-background;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.55);
  border-radius: 4px;
  color: $white;
  display: flex;
  align-items: center;
  &.dark-background {
    background: $dark-light-2;
  }
}
