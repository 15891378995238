@import 'src/styles/base/color';

.eg-selection-modal {
  color: $grey-bright;
  margin: 1rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  .title {
    margin: 1rem 0;
    color: $gold-shadow;
  }
  .text {
    margin: 1rem 0;
  }
  button.btn-highlight {
    padding: 0.75rem 0.5rem;
  }
}
