@import 'src/styles/base/color';

.eg-init-modal {
  color: $grey-bright;
  margin: 1rem 0;
  .title {
    text-align: center;
    color: $gold-shadow;
  }
  .text {
    margin: 1rem 0;
  }
  button {
    min-height: 45px;
  }
}
