@import 'src/styles/base/color';
@import 'src/styles/base/breakpoint';
@import 'src/styles/base/typo-variables';

.profile-form-container {
  color: $white;
  margin: 1rem 1rem 3.5rem;
  max-width: 53rem;
  .text,
  .number {
    font-size: 1.25rem;
    input {
      margin: 0.5rem;
      background: $dark-light-2;
      color: $white;
      border: 1px solid $gold-shadow;
      max-width: 5rem;
      height: 2.5rem;
      text-align: center;
      &:focus {
        outline: $gold-shadow 1px;
      }
      &:disabled {
        -webkit-text-fill-color: $white;
        opacity: 1;
      }
    }
  }
  .profile-question-label {
    font-weight: bold;
    font-size: 1.375rem;
    line-height: 150%;
    padding: 1rem 0;
  }
  .profile-question-hint {
    font-size: 1rem;
    color: $grey-bright;
    display: block;
    margin-bottom: 1rem;
    font-style: italic;
  }
  .profile-question-answers {
    display: flex;
  }
  .profile-question-answers-radio-long {
    flex-wrap: wrap;
  }

  .profile-question-answers-other {
    flex-direction: column;
  }
  .radio_short,
  .radio_long,
  .checkbox {
    background: $grey-linear-gradient-background;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.55);
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    font-weight: bold;
    input {
      margin: 0.5rem 1rem 0.5rem 0.5rem;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      background: $dark-background;
      position: relative;

      // &:focus {
      //   outline: none;
      // }
    }
  }
  .checkbox {
    input {
      border-radius: 1px;
    }
  }
  .radio_short,
  .checkbox {
    padding: 1.2rem;
    border-radius: 4px;
  }
  .radio_long {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    text-align: center;
  }
  .answer-checked {
    background: radial-gradient(
        105.25% 64.58% at 49.68% 70.83%,
        rgba(226, 137, 4, 0.5) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      #f1c017;
    color: $dark-light-2;
    input {
      &:before,
      &:after {
        content: '';
        position: absolute;
        display: inline-block;
        background: $gold-shadow;
        border-radius: 0.5rem;
      }
      &:before {
        width: 3px;
        height: 12px;
        left: 10px;
        top: 4px;
        transform: rotate(41deg);
      }
      &:after {
        width: 3px;
        height: 6px;
        left: 5px;
        top: 8px;
        transform: rotate(133deg);
      }
    }
  }
  .number-container {
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .btn-profile-number {
    background: $grey-linear-gradient-background;
    background-color: transparent;
    border: none;
    border-radius: 2px;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 2rem;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem;
    &:disabled {
      color: rgba($color: $white, $alpha: 0.5);
    }
  }
  .date-select {
    margin: 0.5em;
    border: 1px solid $gold-shadow;
    background: $dark-light-2;
    font-weight: bold;
    .MuiInput-underline:after {
      display: none;
    }
    .year {
      text-align: center;
      display: inline-flex;
      align-content: center;
      font-size: 1.25rem;
      font-family: $text-font;
      color: $white;
      min-width: 130px;
      max-width: 150px;
      svg {
        top: 0;
        right: 0;
        background-color: $gold-shadow;
        height: 100%;
      }
    }
    .month {
      color: $white;
      font-size: 1.25rem;
      text-align: center;
      display: inline-flex;
      align-content: center;
      font-family: $text-font;
      min-width: 130px;
      max-width: 150px;
      svg {
        top: 0;
        right: 0;
        background-color: $gold-shadow;
        height: 100%;
      }
    }
  }

  .select-container {
    display: flex;
  }

  .date-option {
    color: $white;
  }

  ul {
    background: $grey-linear-gradient-background;
    color: $white;
    font-weight: normal;
    .MuiMenuItem-root {
      font-family: $text-font;
      text-align: center;
      font-size: 1.25rem;
      display: flex;
      justify-content: space-evenly;
      :hover {
        background-color: $gold-shadow;
      }
    }
    .MuiListItem-root.Mui-selected,
    .MuiListItem-root.Mui-selected:hover {
      background-color: $gold-shadow;
      color: $dark-2;
      font-weight: bold;
      display: flex;
      justify-content: space-evenly;
    }
  }
}
