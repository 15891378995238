@import 'src/styles/base/color';
@import 'src/styles/base/breakpoint';

.profile-type-finished-card {
  margin: auto;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.55);
  border-radius: 4px;
  transition: all 300ms ease;
  color: $white;
  background: $grey-linear-gradient-background;
  width: 80%;
  text-align: center;
  padding: 1rem;
  @media (min-width: $width-tablet) {
    width: 50%;
  }
  @media (min-width: $width-large-desktop) {
    width: 40%;
  }
  button.profile-type-finished-button {
    margin-top: 2.875rem;
  }
}
.profile-type-finished-label {
  color: $gold-shadow;
}
.profile-type-finished-description {
  margin-top: 1.875rem;
}
