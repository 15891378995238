@import 'src/styles/base/typo-variables';
@import 'src/styles/base/color';

.cardContent {
  &.cardDone {
    border: 1px solid $grey-bright;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;

    .challengeName {
      text-align: center;
    }
    .iconResult {
      display: flex;
      @media all and (max-height: 700px) {
        width: 55%;
        margin: auto;
      }
    }
    .imgResult {
      width: 100%;
      height: 100%;
    }
    .labelResult {
      font-weight: bold;
      font-size: 28px;
      line-height: 120%;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
    }
    .win {
      color: $gold-shadow;
    }
    .lost {
      color: $red-primary;
    }
    .statsResult {
      text-align: center;
    }
    .buttons {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      button {
        padding: 0.625rem;
        margin: 0;
        &.grey-border {
          border: 1px solid $grey-bright;
        }
      }
    }
  }
}
