@import 'src/styles/base/color';
@import 'src/styles/base/typo-variables';

#cookie-banner {
    background-color: $dark-light-3;
    color: $grey-bright;
    font-family: $text-font;
    position: absolute;
    bottom: 75px;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 10;
    padding: 20px;
    border-radius: 8px;
    max-width: 360px;
    margin: 0 10px;

    .buttons {
        margin-top: 8px;
        text-align: right;
        button:first-of-type {
            margin-right: 15px;
        }
        
    }
}