@import 'src/styles/base/color';

.action-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $grey-bright;
  padding: 1.5rem;
  max-width: 400px;
  .action-duration {
    margin-top: 1rem;
  }
  .action-text {
    margin: 1.6rem 0;
  }
  .buttons button {
    margin-top: 0.5rem;
  }
}

#accessibility-title {
  display: none;
}
