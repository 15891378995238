@import 'src/styles/base/color';
@import 'src/styles/base/breakpoint';
@import 'src/styles/base/z-index';

.o-sidebar {
  background-color: $dark-2;
  box-shadow:
    0px 5px 5px rgba(0, 0, 0, 0.2),
    0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.14);
  border-top: unset;
  border-right: unset;
  z-index: 1001; // High z-index to pass over Open Street Map
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 65px;
  transition: height 0.2s ease-out;

  &.with-secondary-nav {
    height: 65px * 2;
  }

  .secondary-c-nav {
    background-color: $dark-2;
  }

  .secondary-c-nav.profile-completed {
    margin-top: 12px;
  }

  .navigation-items-wrapper {
    width: 100%;
    max-width: 550px;
  }
}

.c-nav {
  margin: 1.5rem 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;

  .c-nav-item {
    position: relative;
    z-index: 0;
    height: 54px;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      a,
      svg {
        color: $white !important;
        &.is-active {
          color: $gold;
        }
      }
    }
    .c-nav-link {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $white-light;
      padding: 0 1rem;
      font-size: 0.9rem;
      &:visited {
        color: $white-light;
      }
      .c-nav-icon {
        width: 60px;
        height: 36px;
      }
      .on {
        display: none;
      }
      .off {
        display: block;
      }

      &.is-active {
        color: $white;
        box-shadow: inset 0.25rem 0 0 0 $gold;
        text-decoration: none;
        svg.c-nav-icon {
          fill: $gold;
          color: $gold !important;
        }
        &:focus-visible {
          box-shadow:
            inset 0 0 0 1px $grey-bright,
            inset 0.25rem 0 0 0 $gold;
        }
        .on {
          display: block;
        }
        .off {
          display: none;
        }
      }
    }
  }
}

.secondary-c-nav {
  margin-top: 0;
}

.c-nav-item {
  .c-nav-link {
    padding: 0;
    .c-nav-icon {
      padding: 0;
      margin: auto;
    }
    &.is-active {
      box-shadow: unset;
      &:focus {
        box-shadow: unset;
      }
    }
  }
}

.c-nav {
  margin: 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;

  .c-nav-item {
    height: 54px;
    .c-nav-link {
      padding: 0;
      display: flex;
      flex-direction: column;
      .c-nav-icon {
        padding: 0;
        margin: auto;
      }
      &.is-active {
        box-shadow: unset;
        &:focus {
          box-shadow: unset;
        }
      }
    }
  }
}

.nb-notif {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: -20px;
  right: 0px;
  margin: auto;
  width: 1.25rem;
  height: 1.25rem;
  color: $dark-light;
  border-radius: 50%;
  border: 1px solid $dark-light;
  z-index: 1;
  background: $blue-radial-gradient;
  font-size: 12px;
}

.shrink {
  transform: scale(0.8);
}
