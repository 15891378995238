@import 'src/styles/base/breakpoint';
@import 'src/styles/base/color';
@import 'src/styles/base/z-index';

.header {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  z-index: $z-header;
  position: fixed;

  @media #{$tablet} {
    width: 100%;
  }

  @media #{$large-phone} {
    top: 0;
  }
  .header-top {
    background: radial-gradient(
      51.85% 47.89% at 50% 13.32%,
      $default-background 0%,
      $dark-2 100%
    );
    width: 100%;
    .header-text {
      color: $grey-bright;
    }
  }
  .header-content {
    display: flex;
    flex-direction: column;
    @media #{$tablet} {
      margin: 0;
    }
    @media #{$large-phone} {
      margin: 6px 0 0 0;
    }
    .header-content-top {
      display: flex;
      flex-direction: row;
      &.header-content-top-right {
        justify-content: flex-end;
      }
      .header-text {
        flex: 1;
      }
      .header-text-desktop {
        display: flex;
        flex: 1;
        color: $grey-bright;
      }
      .header-back-button {
        padding: 1rem;
      }
      @media #{$large-phone} {
        .header-text {
          color: $grey-bright;
        }
        // .header-text-desktop {
        //   display: none;
        // }
        // .header-feedbacks-button {
        //   display: none;
        // }
      }
    }
  }
}

.building-title {
  text-align: center;
}
