@import '../base/color';
@import '../base/mixins';

button {
  &.btn-highlight {
    @include button($gold-shadow, #000000, none, $multi-color-radial-gradient) {
      background-color: darken($gold-shadow, 12%);
    }
  }
  &.btn-primary-positive {
    @include button(
      transparent,
      $gold-shadow,
      1px solid $dark-light-2,
      transparent
    ) {
      background-color: rgba($dark-light-2, 0.2);
      span:first-child {
        color: rgba($gold-shadow, 0.7);
      }
    }
  }
  &.btn-primary-challenge {
    @include button($blue-light, black, 1px solid $blue-light, transparent) {
      background-color: rgba($blue-light, 0.2);
      span:first-child {
        color: black;
      }
    }
  }
  &.btn-secondary {
    @include button(transparent, $gold-euro, 1px solid $grey-dark);
  }
  &.btn-secondary-positive {
    @include button(transparent, $grey-bright, 1px solid $white, transparent) {
      background-color: rgba($dark-light-2, 0.2);
      span:first-child {
        color: rgba($grey-bright, 0.7);
      }
    }
  }
  &.btn-secondary-negative {
    @include button(
      transparent,
      $grey-bright,
      1px solid $grey-dark,
      transparent
    ) {
      background-color: rgba($grey-dark, 0.2);
      span:first-child {
        color: rgba($grey-bright, 0.7);
      }
    }
  }
  &.btn-duel-off {
    @include button(
      $dark-light-2,
      $white,
      1px solid rgba(97, 240, 242, 0.5),
      $dark-light-2
    ) {
      background-color: darken($dark-light-2, 12%);
    }
  }
  &.btn-duel-active {
    border-radius: 4px !important;
    @include button($blue-radial-gradient, $dark-light-2, none);
  }
  &.btn-duel-on {
    @include button($dark-light-2, $white, 1px solid $blue, $dark-background) {
      background-color: darken($dark-light-2, 12%);
    }
  }
  &.btn-profile-next {
    @include button(
      $gold-shadow,
      #000000,
      1px solid $gold-shadow,
      $multi-color-radial-gradient
    ) {
      background-color: darken($gold-shadow, 12%);
    }
  }
  &.btn-profile-back {
    @include button(none, $grey-bright, 1px solid $grey-bright, none);
  }
}
