@import 'src/styles/base/breakpoint';
@import 'src/styles/base/typo-variables';
@import 'src/styles/base/color';
@import 'src/styles/base/z-index';

.challengeSlider {
  width: 100%;
  position: relative;
  user-select: none;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
.challenge-container {

  // @media all and (min-width: $width-phone) {
  //   position: relative;
  //   width: 100%;
  //   display: flex;
  //   flex: 1;
  //   align-items: center;
  //   transition: all 300ms ease;
  // }

}
.cardContent {
  margin: auto;
  cursor: pointer;
  .title {
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    font-family: $text-font;
    font-size: 1.2rem;
    margin-bottom: 0;
  }
  .challengeTitle {
    display: block;
    font-weight: 700;
    text-align: center;
    font-family: $text-font;
    color: $grey-bright;
    font-size: 1.5rem;
    margin-top: 1rem;
  }
  .challengeIcon {
    width: 100%;
    max-width: 180px;
    margin: auto;
    height: auto;
    text-align: center;
    display: block;
  }
}
.sliderButtons {
  text-align: center;
  margin: auto;
  margin-top: 1.5rem;
  @media all and (max-width: $width-tablet) {
    display: none;
  }
}
