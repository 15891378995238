.fluid-icon {
    &.disabled {
        opacity: 0.4;
    }
    
}

.red-strike {
    position: absolute;
    width: 48px;
    height: 3px;
    background-color: red;
    transform: rotate(309deg);
    transform-origin: bottom left;
    bottom: 8px;
    left: 14px;
    opacity: 0.7;
}