@import 'src/styles/base/color';
@import 'src/styles/base/breakpoint';
@import 'src/styles/base/z-index';

.splash-root {
  position: fixed;
  z-index: $z-splash;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  &.splash-fade {
    opacity: 0;
  }
}
