/* Cozy UI utilities classes */
// @import '~cozy-ui/dist/cozy-ui.min.css';
/* Cozy UI React components styles */
// @import '~cozy-ui/transpiled/react/stylesheet.css';
/* App styles */
@import 'base/layout';
@import 'base/color';
@import 'base/breakpoint';
@import 'base/typography';
@import 'components/barchart';
@import 'components/buttons';
@import 'components/card';
@import 'components/dialog';
@import 'components/expansion-panel';
@import 'components/link';

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

:root {
  --blue: #{$blue};
  --blue40: #{$blue-40};
  --blueBackground: #{$blue-radial-gradient};
  --blueRadialGradient: #{$blue-radial-gradient};
  --blueRadialGradientTrans: #{$blue-radial-gradient-transparent};
  --elecColor: #{$elec-color};
  --elecColor40: #{$elec-color-40};
  --elecCompareColor: #{$elec-compare-color};
  --gasColor: #{$gas-color};
  --gasColor40: #{$gas-color-40};
  --gasCompareColor: #{$gas-compare-color};
  --waterColor: #{$water-color};
  --waterColor40: #{$water-color-40};
  --waterCompareColor: #{$water-compare-color};
  --multiColor: #{$multi-color};
  --multiCompareColor: #{$multi-compare-color};
  --redPrimary: #{$red-primary};
  --greyBright: #{$grey-bright};
  --greyDark: #{$grey-dark};
  --textWhite: #{$white};
  --softGrey: #{$soft-grey};
  --darkLight: #{$dark-light};
  --darkLight2: #{$dark-light-2};
  --textFont: #{$text-font};
  --greyLinearGradientBackground: #{$grey-linear-gradient-background};
  --multiColorRadialGradientTrans: #{$multi-color-radial-gradient-transparent};
  --elecColorRadialGradientTrans: #{$elec-color-radial-gradient-transparent};
  --waterColorRadialGradientTrans: #{$water-color-radial-gradient-transparent};
  --gasColorRadialGradientTrans: #{$multi-color-radial-gradient-transparent};
  --multiColorRadialGradient: #{$multi-color-radial-gradient};
  --elecColorRadialGradient: #{$elec-color-radial-gradient};
  --gasColorRadialGradient: #{$gas-color-radial-gradient};
  --waterColorRadialGradient: #{$water-color-radial-gradient};
}

.application {
  // @media #{$large-phone} {
  //   min-height: calc(
  //     100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)
  //   );
  // }
  overflow-x: hidden;
  background-color: $default-background;
}

.accordion-icon {
  color: $white;
}

.loaderContainer {
  height: 80vh;
  display: flex;
  flex: 1;
  justify-content: center;
  // useful when text is rendered with loader
  align-items: center;
}
