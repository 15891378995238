@import 'src/styles/base/breakpoint';
@import 'src/styles/base/color';

.content-view {
  position: relative;
  background-color: $default-background;
  display: flex;
  flex-direction: column;
  width: 100%;
}
