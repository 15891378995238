.profile-type-container {
  position: relative;
  min-height: inherit;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  label,
  input {
    cursor: pointer;
  }
}
