@import 'src/styles/base/color';
@import 'src/styles/base/breakpoint';

.gcu-content-root {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  padding: 0 2rem;
  .gcu-content-wrapper {
    max-width: 36rem;
    margin: 2rem 0;
    color: $grey-bright;
    width: 100%;
    .version {
      color: $soft-grey;
      text-align: center;
    }
    .gcu-content-title,
    .gcu-content-subtitle {
      text-align: center;
    }

    ol {
      padding-left: 0;
    }

    p {
      color: $grey-bright;
      margin-top: 1rem;
      font-size: 0.9em;
    }

    p:last-child {
      margin-bottom: 1rem;
    }

    .gcu-content-part-title {
      margin-top: 1.25rem;
      color: $gold-shadow;

      li {
        color: $grey-bright;
      }
    }
  }
}
