@import 'src/styles/base/typo-variables';
@import 'src/styles/base/color';

.cardLast {
  width: 100%;
  height: inherit;
  box-sizing: border-box;
  padding: 5%;
  transition: all 300ms ease;
  border: 1px solid $grey-bright;
  border-radius: 4px;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  .content {
    max-height: 350px;
    flex-direction: column;
    display: flex;
  }
  svg {
    max-height: 150px;
  }
  .title-last {
    padding: 1rem 2.3rem 1.5rem;
  }
  .message {
    margin: 0 0 0.7rem;
  }
  .btn_lastCard {
    padding: 8px;
    max-height: 40px;
  }
  .content,
  .title-last,
  .message,
  .btn_lastCard,
  svg {
    flex: 1;
  }
}
