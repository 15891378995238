@import 'src/styles/base/color';
@import 'src/styles/base/breakpoint';

.ecogesture-profile-container {
  .progress-wrapper {
    padding: 1rem 0;
  }
}

.equipment-form-container {
  padding: 1.5rem;
  color: $grey-bright;

  .equipment-label {
    @media (min-width: $width-large-phone) {
      text-align: center;
    }
  }
  .equipment-hint {
    margin: 1rem 0;
    @media (min-width: $width-large-phone) {
      text-align: center;
    }
  }
  .icons-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    max-width: 400px;
    margin: 1.5rem auto 3rem auto;
    .checkbox-equipment {
      cursor: pointer;
      max-width: 80px;
      text-align: center;
      margin: 0 auto;
      .text {
        margin-top: 0.5rem;
        text-align: center;
      }
    }
    input.item-eq {
      display: none;
    }
    .equipment-icon-container {
      display: flex;
      transition: all 200ms ease;
      box-sizing: border-box;
      flex-direction: column;
      width: 80px;
      height: 80px;
      border-radius: 5px;
      background-color: $grey-bright;
      &.checked {
        background-color: $gold-shadow;
      }
      .equipmentIcon {
        margin: auto;
      }
    }
  }
}
