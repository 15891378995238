@import 'src/styles/base/color';

.action-ongoing {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 600px;
  padding: 1.5rem;
  min-height: 75vh;
  margin: auto;
  .duration,
  .result-title {
    color: $grey-bright;
  }
  .description {
    color: white;
    margin-top: 1rem;
  }
  .result-date {
    color: $blue-light;
    margin-bottom: 2rem;
  }
  button.btn-detail {
    padding: 0.3rem;
  }
  .duration-image {
    position: relative;
    padding: 1rem;
    margin: 3rem 0;
    .pie {
      width: 9rem;
      height: 9rem;
      display: block;
      border-radius: 50%;
      background-color: $dark-light-2;
      border: 4px solid $dark-light-2;
      position: relative;
      top: 5px;
      left: -2px;
      z-index: 0;
    }
    .duration-icon {
      position: absolute;
      left: -25px;
      top: -25px;
      z-index: 0;
    }
  }
}
