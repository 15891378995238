@import 'src/styles/base/color';
@import 'src/styles/base/breakpoint';

#notifs-list-container {
  margin-top: 50px;
  color: $grey-bright;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  .notif-card-wrapper {
    max-width: 450px;
    padding: 0 10px;
  }

  .ec-empty-content {
    text-align: center;
    padding: 0 1.5rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    @media (min-width: $width-phone) {
      max-width: 45%;
    }
    @media (min-width: $width-desktop) {
      max-width: 35%;
    }

    .btn-container {
      margin-top: 1rem;
      display: flex;
      gap: 1rem;
      button {
        margin: 0;
        & .btn-highlight {
          color: $dark;
        }
      }
    }
  }
}
