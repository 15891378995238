@import '../base/color';
@import '../base/breakpoint';

.axis {
  color: $grey-dark;
  .tick-text {
    fill: $grey-dark;
    &.tick-text-selected {
      fill: $grey-bright;
    }
  }
  .separator {
    text-align: center;
    margin: 0 2px;
    font-size: 1rem !important;
  }
}
.value-text {
  fill: $grey-dark;
  &.selected {
    fill: $grey-bright;
  }
}

.barContainer,
.barFill {
  &.disabled * {
    cursor: default !important;
  }
  &:hover {
    cursor: pointer;
  }
}
.background-true {
  opacity: 0.1;
  &:hover {
    cursor: pointer;
  }
}
.background-false {
  opacity: 0;
}
.bar-compare-ELECTRICITY,
.bar-compare-GAS,
.bar-compare-WATER,
.bar-compare-MULTIFLUID,
.bar-MULTIFLUID,
.bar-WATER,
.bar-GAS,
.bar-ELECTRICITY {
  &:hover {
    cursor: pointer;
    &.disabled {
      cursor: default;
    }
  }
}
.bar-ELECTRICITY {
  fill: $elec-off-color;
  &.selected {
    fill: $elec-color;
    filter: drop-shadow(0 -0.1rem 0.2rem $elec-color);
  }
}

.bar-compare-ELECTRICITY {
  fill: $elec-compare-off-color;
  &.selected {
    fill: $elec-compare-color;
    filter: drop-shadow(0 -0.1rem 0.2rem $elec-compare-color);
  }
}

.bar-WATER {
  fill: $water-off-color;
  &.selected {
    fill: $water-color;
    filter: drop-shadow(0 -0.1rem 0.2rem $water-color);
  }
}

.bar-compare-WATER {
  fill: $water-compare-off-color;
  &.selected {
    fill: $water-compare-color;
    filter: drop-shadow(0 -0.1rem 0.2rem $water-compare-color);
  }
}

.bar-GAS {
  fill: $gas-off-color;
  &.selected {
    fill: $gas-color;
    filter: drop-shadow(0 -0.1rem 0.2rem $gas-color);
  }
}

.bar-compare-GAS {
  fill: $gas-compare-off-color;
  &.selected {
    fill: $gas-compare-color;
    filter: drop-shadow(0 -0.1rem 0.2rem $gas-compare-color);
  }
}

.bar-MULTIFLUID {
  fill: $multi-off-color;
  &.selected {
    fill: $multi-color;
    filter: drop-shadow(0 -0.1rem 0.2rem $multi-color);
  }
  &.disabled {
    &:hover {
      cursor: default;
    }
  }
}
.bar-duel {
  fill: $blue-light;
  &.selected {
    fill: $blue-light;
    filter: drop-shadow(0 -0.1rem 0.2rem $blue-light);
  }
  &.disabled {
    &:hover {
      cursor: default;
    }
  }
}
.bar-UNCOMING {
  fill: $grey-dark;
  opacity: 0.6;
  &.selected {
    fill: $grey-dark;
  }
  &.disabled {
    &:hover {
      cursor: default;
    }
  }
}

.bar-compare-MULTIFLUID {
  fill: $multi-compare-off-color;
  &.selected {
    fill: $multi-compare-color;
    filter: drop-shadow(0 -0.1rem 0.2rem $multi-color);
  }
}
.bar-average {
  stroke-width: 2;
  stroke: $multi-color;
}
.week {
  fill: $elec-compare-color;
  &.selected {
    fill: $elec-compare-color;
    filter: drop-shadow(0 -0.1rem 0.2rem $elec-compare-color);
  }
}
.weekend {
  fill: $multi-compare-color;
  &.selected {
    fill: $multi-compare-color;
    filter: drop-shadow(0 -0.1rem 0.2rem $multi-color);
  }
}
/** Animation **/
.bounce-1 {
  animation-name: bounce-1;
  animation-timing-function: cubic-bezier(1, 1, 0.42, 1);
  animation-iteration-count: 1;
  transform-origin: bottom center;
  transform-box: fill-box;
}
@keyframes bounce-1 {
  0% {
    transform: scale(0.1, 0.1);
  }
  28% {
    transform: scale(0.1, 1.1);
  }
  45% {
    transform: scale(0.8, 0.95);
  }
  55% {
    transform: scale(1, 1);
  }
  65% {
    transform: scale(1, 0.98);
  }
  75% {
    transform: scale(1, 1);
  }
}

.bounce-2 {
  animation-name: bounce-2;
  animation-timing-function: cubic-bezier(1, 1, 0.42, 1);
  animation-iteration-count: 1;
  transform-origin: bottom center;
  transform-box: fill-box;
}
@keyframes bounce-2 {
  0% {
    transform: scale(1, 1);
  }
  28% {
    transform: scale(1, 1.1);
  }
  45% {
    transform: scale(0.8, 0.95);
  }
  55% {
    transform: scale(1, 1);
  }
  65% {
    transform: scale(1, 0.98);
  }
  75% {
    transform: scale(1, 1);
  }
}

.bounce-3 {
  animation-name: bounce-3;
  animation-timing-function: cubic-bezier(1, 1, 0.42, 1);
  animation-iteration-count: 1;
  transform-origin: bottom center;
}
@keyframes bounce-3 {
  0% {
    opacity: 0.6;
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scaleY(1);
    opacity: 1;
  }
}

/** Animation delay **/
.delay {
  animation-duration: 0.4s;
}

$animation-duration-base: 0.4s; // Base animation duration

@for $i from 0 through 48 {
  .delay--#{$i} {
    animation-duration: $animation-duration-base + $i * 0.08s;
  }
}
