@import 'src/styles/base/color';
@import 'src/styles/base/breakpoint';

.quiz-finish-container {
  padding: 1.5rem;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.55);
  border-radius: 4px;
  transition: all 300ms ease;
  color: $white;
  background: $grey-linear-gradient-background;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  button.btn-secondary-negative {
    border-color: $grey-bright;
  }
  .button-start {
    margin-top: 3rem;
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
}
.score-final-title {
  font-weight: bold;
  font-size: 2rem;
  margin: 2rem 0 0.5rem;
}
.score-final {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 2rem;
  span {
    color: $blue-light;
  }
}
.score-label {
  font-size: 1.15rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.score-stars {
  font-size: 1.8rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $blue-light;
  .quiz-icon {
    margin-left: 0.5rem;
  }
}
