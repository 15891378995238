@import 'src/styles/base/color';
@import 'src/styles/base/breakpoint';

.exploration-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.55);
  border-radius: 4px;
  transition: all 300ms ease;
  color: $white;
  background: $grey-linear-gradient-background;
  width: 80%;
  text-align: center;
  padding: 1rem 1.5rem 1rem;
  font-size: 1.1rem;
  font-weight: 700;
  @media (min-width: $width-tablet) {
    width: 50%;
  }
  @media (min-width: $width-large-desktop) {
    width: 40%;
  }
  .exploration-finish {
    width: 75%;
    @media (min-width: $width-large-phone) {
      width: auto;
    }
  }
  button.btn-secondary-negative {
    border-color: $grey-bright;
    margin-top: 1rem;
  }
  .exploration-icon {
    margin-left: 0.5rem;
  }
  .congratulation {
    font-size: 2.3rem;
    margin: 2rem 0 1rem;
  }
  .exploration-earn {
    margin: 2rem 0 1rem;
  }
}
