@mixin button(
  $background,
  $text-color,
  $border: none,
  $background-gradient: $background
) {
  background: $background-gradient;
  background-color: $background;
  border: $border;
  border-radius: 2px;
  margin: 1.5rem 0 0;
  width: 100%;
  text-transform: initial;
  cursor: pointer;
  span:first-child {
    color: $text-color;
  }
  &:hover,
  &:focus,
  &.active,
  &:disabled {
    @content;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
@mixin checkBox() {
  .checkbox {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    &:first-of-type {
      margin-top: 1.5rem;
    }
    input {
      margin: 0.5rem 1rem 0.5rem 0.5rem;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      width: 1.45rem;
      height: 1.45rem;
      min-width: 1.45rem;
      min-height: 1.45rem;
      background: $dark-background;
      position: relative;
      border: solid 2px $gold-shadow;
      cursor: pointer;
    }
  }
  .answer-checked {
    input {
      &:before,
      &:after {
        content: '';
        position: absolute;
        display: inline-block;
        background: $gold-shadow;
        border-radius: 0.5rem;
      }
      &:before {
        width: 3px;
        height: 12px;
        left: 10px;
        top: 4px;
        transform: rotate(41deg);
      }
      &:after {
        width: 3px;
        height: 6px;
        left: 5px;
        top: 8px;
        transform: rotate(133deg);
      }
    }
  }
}
