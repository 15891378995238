@import 'src/styles/base/color';
@import 'src/styles/base/breakpoint';

.exploration-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 1.5rem;
}
.exploration-begin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 2rem 1rem 1.5rem;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.55);
  border-radius: 4px;
  transition: all 300ms ease;
  color: $white;
  background: $grey-linear-gradient-background;

  svg {
    margin-top: -33%;
  }

  text-align: center;
  @media (min-width: $width-large-phone) {
    width: 60%;
    height: 100%;
  }
  @media (min-width: $width-large-desktop) {
    max-width: 45%;
    padding: 0.5rem 1rem 2rem;
  }
  .btn-start {
    margin-top: auto;
    border-color: $grey-bright;
  }
  .exploration-icon-stars {
    margin-top: -4rem;
  }
  .exploration-explanation {
    line-height: 24px;
    height: 50%;
    display: flex;
    flex-direction: column;
    margin: 2.5rem 0;
    div {
      align-self: center;
    }
    div:first-child {
      margin-bottom: 1rem;
    }
  }
  .button-start {
    margin-top: 0;
    width: 100%;
    button.btn-secondary-negative {
      margin: 0;
    }
  }
  .stars {
    margin-top: 1rem;
    svg {
      margin-right: 0.25rem;
    }
  }
}
