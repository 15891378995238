@import 'src/styles/base/color';

.exploration-error-container {
  display: flex;
  min-height: 60vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 1.5rem;
  color: $grey-bright;
}
.exploration-error-message {
  margin-top: 3rem;
  text-align: center;
}
.exploration-error-button {
  margin-top: 3rem;
  width: 7.5rem;
}
