@import 'src/styles/base/color';
@import 'src/styles/base/breakpoint';

.eg-selection-detail-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: $grey-bright;
  padding: 0 1.5rem;
  flex: 1;
  max-height: calc(100vh - 60px - 72px - 0px);

  .content {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;

    .title {
      color: $soft-grey;
      font-weight: 700;
    }

    .iconContainer {
      height: 240px;
    }

    .showMore {
      text-align: center;
      text-decoration: underline;
      margin-top: 1rem;
      cursor: pointer;
    }

    .longDescription {
      margin: 1rem 0.5rem;
      text-align: left;
    }
  }
  .buttons {
    margin: 1rem 0;
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    button.btn-secondary-negative {
      margin: 0;
      padding: 0.75rem 0.25rem;
      height: 7.375rem;
      border: 1px solid $grey-bright;
      border-radius: 0.25rem;
      &:focus,
      &.active,
      &:disabled,
      &:hover {
        background: transparent;
      }

      span {
        flex-direction: column;
        color: $grey-bright;
      }
    }
    button.noFocus {
      background: transparent;
    }
    .column-flex {
      display: flex;
      flex-direction: column;
    }
  }
}
