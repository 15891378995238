@import 'src/styles/base/color';
@import 'src/styles/base/breakpoint';

.action-begin {
  margin: auto;
}
.action-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 1.5rem;
}
.action-begin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.55);
  border-radius: 4px;
  transition: all 300ms ease;
  color: $white;
  background: $grey-linear-gradient-background;
  height: auto;
  gap: 1.5rem;
  text-align: center;
  position: relative;
  padding: 5rem 1rem 1rem 1rem;
  @media (min-width: $width-large-desktop) {
    margin: 6rem 1rem 1rem 1rem;
  }
  .icon-container {
    width: 8.125rem;
    height: 8.125rem;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -70px;
    background: $grey-linear-gradient-background;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.55);
    border-radius: 8px;
    border: solid 2px $blue;
    display: flex;
  }
  .action-icon {
    margin: auto;
  }
  .action-icon-stars {
    margin-top: -4rem;
  }
  .stars {
    .star:not(:last-of-type) {
      margin-right: 0.4rem;
    }
  }
  .action-duration {
    color: $grey-bright;
  }
  .action-explanation {
    margin-bottom: 2rem;
    line-height: 24px;
    padding: 1rem;
    @media (min-width: $width-large-desktop) {
      padding: 0rem 2rem;
    }
  }
  .action-buttons {
    button {
      padding: 0.7rem;
      margin: 0.5rem;
      span {
        font-weight: 700;
      }
    }
  }
}
