@import 'breakpoint';
@import 'color';
@import 'typo-variables';

* {
  font-family: $text-font;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: $soft-grey;
  font-family: $text-font;
}

.home-title {
  font-size: 1rem;
  font-family: $text-font;
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
  text-transform: uppercase;
}

.app-title {
  font-family: $text-font;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.15px;
  color: $grey-bright;
  text-shadow: 0px -1px 0px #060609, 0px 1px 0px rgba(255, 255, 255, 0.07);
}

@each $name, $size in $text-size {
  .text-#{$name} {
    font-style: normal;
    font-size: $size;
    line-height: 120%;
    &-normal {
      @extend .text-#{$name};
      font-weight: normal;
      &-uppercase {
        @extend .text-#{$name}-normal;
        text-transform: uppercase;
      }
      &-150 {
        @extend .text-#{$name}-normal;
        line-height: 150%;
      }
    }
    &-bold {
      @extend .text-#{$name};
      font-weight: 700;
      &-uppercase {
        @extend .text-#{$name}-bold;
        text-transform: uppercase;
      }
      &-capitalize {
        @extend .text-#{$name}-bold;
        text-transform: capitalize;
      }
    }
    &-italic {
      @extend .text-#{$name};
      font-style: italic;
      font-weight: normal;
    }
  }
}

/* Card */
.card-title-on {
  font-family: $text-font;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 120%;
  color: $grey-bright;
}
.card-text-bold {
  font-family: $text-font;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 120%;
  color: $grey-bright;
}
.card-indicator {
  font-family: $text-font;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 120%;
  color: $grey-bright;
}
.card-text {
  font-family: $text-font;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 120%;
  color: $grey-bright;
}

/* Chart */
.chart-ticks-x-text {
  font-family: $text-font;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 120%;
  @media #{$large-phone} {
    font-size: 0.685rem;
  }
}
.chart-ticks-y-text {
  font-family: $text-font;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 120%;
  @media #{$large-phone} {
    font-size: 0.75rem;
  }
}

/* Cozy bar */
.cozybar {
  font-family: $text-font;
  font-style: normal;
  font-weight: bold;
  font-size: 1.3125rem;
  line-height: 120%;
  color: $grey-bright;
}
