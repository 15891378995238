@import 'src/styles/base/color';
@import 'src/styles/base/breakpoint';

.eg-selection-end-container {
  position: relative;
  min-height: inherit;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $grey-bright;
  max-width: 45.75rem;
  @media #{$large-phone} {
    margin: 0 1rem;
    max-width: unset;
  }
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    .title {
      color: $gold-shadow;
      margin: 1rem;
      @media all and (min-width: $width-tablet) {
        margin: 5rem;
      }
    }
  }
  .buttons {
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    button.btn-highlight {
      padding: 0.75rem 0.5rem;
      margin: 0 0.5rem;
      max-width: 25rem;
    }
  }
}
