@import 'src/styles/base/color';

.notifications-tabs {
  background: transparent;
  color: $white;
  display: flex;
  justify-content: center;
  margin: 0 1rem;
  box-sizing: border-box;
  button {
    border-radius: 4px 4px 0 0;
  }
  .single-tab {
    width: 32%;
    text-transform: initial;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2rem;
    color: $soft-grey;

    &.active {
      font-weight: 700;
      color: $gold-shadow;
    }
  }
  .indicator-tab {
    background: $gold-shadow;
    height: 1px;
    &::before {
      content: '';
      position: absolute;
      height: 12px;
      left: 10.41%;
      right: 10.42%;
      bottom: -6px;
      background: $gold-shadow;
      opacity: 0.35;
      filter: blur(10px);
    }
  }
}
