@import 'src/styles/base/color';

.duel-last-modal-root {
  text-align: center;
  .closeIcon {
    float: right;
    cursor: pointer;
  }
  .icon {
    margin: 2rem 0 0;
  }
  .title {
    margin: 1rem 0 1rem;
  }
  .subtitle {
    color: $blue-light;
    margin: 1rem 0 0.5rem;
  }
  .content {
    margin: 0.5rem 0;
  }
}

#accessibility-title {
  display: none;
}
