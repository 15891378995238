@import 'src/styles/base/color';

.quiz-modal-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .quiz-modal-answer {
    font-weight: bold;
    font-size: 1.7rem;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 1rem;
    &.correct {
      color: $gold-shadow;
    }
    &.wrong {
      color: $red-primary;
    }
  }
  .answer-label {
    margin-bottom: 1.5rem;
  }

  button.btn-secondary-negative {
    width: auto;
    padding: 0.5rem 3rem;
    border-color: $grey-bright;
  }
}

#accessibility-title {
  display: none;
}
