@import 'src/styles/base/color';
@import 'src/styles/base/breakpoint';

.quiz-content {
  outline: none;
  margin: auto;
  .quiz-container {
    .question-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1.5rem;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.55);
      border-radius: 4px;
      transition: all 300ms ease;
      color: $white;
      background: $grey-linear-gradient-background;
      position: relative;
      @media (min-width: $width-large-phone) {
        height: 45vh;
      }

      .question-loading {
        min-height: 13.875rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .question {
        color: $grey-bright;
        text-align: center;
        margin-bottom: 2rem;
        @media (min-width: $width-large-phone) {
          padding: 0 1rem;
        }
      }
      .question-title {
        color: $blue-light;
        margin-bottom: 0.5rem;
      }
      .btn-back {
        color: $white;
        background: none;
        border: none;
        padding: 0;
        font-size: 1.3rem;
        position: absolute;
        top: 1rem;
        right: 1rem;
      }
    }
    .answer {
      text-align: center;
      width: 100%;
      @media (min-width: $width-large-phone) {
        max-width: 80%;
      }
      input[type='radio'] {
        position: fixed;
        opacity: 0;
        pointer-events: none;
      }
      input[type='radio']:focus + label {
        background: $blue-light;
        color: $dark-light-2;
        border-color: $blue-light;
      }
      label {
        display: block;
        border: 1px solid $grey-bright;
        padding: 0.5rem;
        margin-bottom: 1rem;
        cursor: pointer;
      }
      input[type='radio']:checked + label,
      label:hover {
        background: $blue-radial-gradient;
        color: $dark-light-2;
        border-color: $blue-light;
      }
    }
    button.validate {
      margin-top: 1rem;
      width: auto;
      padding: 0.5rem 3rem;
    }

    .index-question {
      margin: 2rem 0 1rem;
    }
  }
}
