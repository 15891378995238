@import 'src/styles/base/color';

.login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $default-background;
  height: 100%;
  width: 100%;

  .form-section {
    text-align: center;
    .eco-gagnant-logo-wrapper {
      margin-bottom: 20px;
      img {
        width: 100%;
        height: 100%;
        max-width: 200px;
      }
    }

    .catch-phrase {
      font-size: 0.9em;
      max-width: 300px;
      color: rgb(243, 243, 243);
    }
  }

  .logos-section-top {
    background-color: white;
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    .wrapper {
      padding: 5px;
      img {
        width: 100%;
        max-width: 550px;
        height: 100%;
      }
    }
  }

  .logos-section-bottom {
    position: absolute;
    bottom: 0;
    padding: 5px;
    margin-bottom: 10px;

    span {
      font-size: 0.7em;
      color: white;
    }

    .logos {
        max-width: 230px;
        img {
          width: 100%;
          height: 100%;
        }
    }
  }
}
