@import 'src/styles/base/color';

button.action-card {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 10rem;
  background: $grey-linear-gradient-background;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.55);
  border-radius: 4px;
  padding: 1rem;
  .action-title {
    text-align: left;
    color: white;
    margin-left: 1rem;
    text-transform: initial;
    font-size: 1.2rem;
  }
}
