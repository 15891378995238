.helper-text {
  max-width: 350px;
  margin: 1rem 1rem 3.5rem;
  color: #fff;

  .toggler {
    display: flex;
    cursor: pointer;
    margin-top: 5px;
    text-decoration: underline;
  }
}
