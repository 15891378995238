@import '../base/color';

.card {
  background: $grey-linear-gradient-background;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  padding: 16px;
  &:hover {
    background: $grey-linear-gradient-background-hover;
  }
  &.rich-card {
    padding: 16px;
  }
}
