@import 'src/styles/base/color';
@import 'src/styles/base/breakpoint';

.quiz-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 1.5rem;
}
.quiz-begin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.55);
  border-radius: 4px;
  transition: all 300ms ease;
  color: $white;
  background: $grey-linear-gradient-background;
  text-align: center;

  svg {
    margin-top: -33%;
  }

  @media (min-width: $width-large-phone) {
    max-width: 60%;
    justify-content: space-evenly;
  }
  @media (min-width: $width-large-desktop) {
    max-width: 40%;
    padding: 0.5rem 1rem 2rem;
  }
  .btn-secondary-negative {
    margin-top: auto;
    border-color: $grey-bright;
  }
  .quiz-icon-stars {
    margin-top: -4rem;
  }
  .quiz-explanation {
    margin-bottom: 2rem;
    line-height: 24px;
    padding: 1rem;
    @media (min-width: $width-large-desktop) {
      padding: 0rem 2rem;
    }
  }
}
