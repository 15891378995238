@import 'src/styles/base/color';

.cardLocked {
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.55));
  .challengeIcon {
    @media all and (max-height: 700px) {
      width: 60%;
      margin: auto;
    }
  }
}
.toUnlock {
  text-align: center;
  color: $grey-bright;
  font-weight: 700;
}
