@import 'src/styles/base/breakpoint';
@import 'src/styles/base/color';

html {
  height: 100%;
  body {
    height: 100%;
    div#root {
      height: 100%;
      width: 100%;
      display: flex;

      main.app-content {
        height: 100%;
        width: 100%;
        background-color: $default-background;
        overflow-x: hidden;
      }

      .head {
        color: #e0e0e0;
        margin-bottom: 1rem;
      }

      .text-16-normal-uppercase {
        text-transform: uppercase;
        font-style: normal;
        font-size: 1rem;
        line-height: 120%;
        font-weight: normal;
      }

      .test-15-normal {
        font-style: normal;
        font-size: 0.938rem;
        line-height: 120%;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 2px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
}
