@import 'src/styles/base/color';

.action-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin: auto;
  gap: 1rem;
  width: 100%;
  box-sizing: border-box;
  padding: 0 1.5rem;
}
