@import 'src/styles/base/color';

.unsubscribe-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 450px;
  margin: auto;
  padding: 2rem;
  text-align: center;
  .head {
    color: $gold-shadow;
  }
  .question {
    color: $white;
    margin-top: 1.5rem;
  }
}
