@import 'src/styles/base/color';

.fb-root {
  overflow-y: auto;
  min-width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    text-align: center;
  }

  .title {
    color: $gold-light;
    font-weight: 700;
    padding: 0.5rem;
  }

  .text {
    color: $grey-bright;
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    gap: 16px;
    button {
      margin-top: 0;
      max-width: 150px;
    }
  }
}
