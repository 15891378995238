@import 'src/styles/base/color';
@import 'src/styles/base/mixins';
@import 'src/styles/base/breakpoint';

.sge-view {
  box-sizing: border-box;
  min-height: inherit;
  width: inherit;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  .sge-container {
    padding: 1rem;

    .sge-step-container {
      color: $grey-bright;
      .title,
      .head {
        margin-top: 1rem;
      }
      label {
        display: block;
        margin-top: 1rem;
        margin-bottom: 0.625rem;
        cursor: pointer;
        span span {
          color: $gold-shadow;
        }
      }
      #zipCode {
        max-width: 115px;
      }
      @include checkBox();
      input:not([type='checkbox']) {
        appearance: none;
        max-width: 280px;
        width: 100%;
        height: 45px;
        border-radius: 4px;
        border: 1px solid $grey-dark;
        background: rgba(0, 0, 0, 0.3);
        transition: all 300ms ease;
        color: $grey-bright;
        padding: 0 0.5rem;
        &:focus,
        &:focus-visible {
          border: 1px solid $gold-shadow;
          outline: none;
        }
      }
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
      }
      .pdl-hint {
        display: inline-block;
        cursor: pointer;
        border-bottom: solid 1px $grey-bright;
        text-align: center;
        margin: 1rem auto auto;
        padding: 0.2rem;
        @media (min-width: $width-tablet) {
          margin-left: 0;
        }
      }
      ul {
        padding: 0 1rem 1rem;
        line-height: 130%;
        border-bottom: 1px solid $grey-dark;
        li {
          margin: 0.5rem 0;
        }
      }
    }
  }
}
.sgeHintModal {
  text-align: center;
  .title {
    color: $gold-shadow;
    margin: 1rem 0;
  }
  .pdl {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: #eff2f4;
    text-align: left;

    p {
      color: #526576;
      margin: 0;
    }

    .bill {
      display: flex;
      gap: 0.5rem;
    }

    svg.prm {
      width: 100%;
      height: 180px;
    }
    svg.bill {
      width: 150px;
      height: 80px;
    }
  }
}
