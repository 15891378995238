@import 'src/styles/base/typo-variables';

.noFluidModal {
  padding: 1rem;
  max-width: 20rem;

  .no-fluid-title {
    text-transform: uppercase;
    font-size: 1.8rem;
    color: white;
    font-family: $text-font;
    margin: 0.5rem auto;
    text-align: center;
  }
  .no-fluid-content {
    font-size: 1.1rem;
    color: white;
    font-family: $text-font;
    text-align: center;
  }
  button.btn-secondary-negative {
    display: block;
    text-align: center;
    margin: auto;
    margin-top: 3.5rem;
    margin-bottom: 0;
  }
}
.nofluid-portal {
  .modal-overlay {
    .modal-box {
      max-width: 21rem;
    }
  }
}

#accessibility-title {
  display: none;
}
