@import 'src/styles/base/color';
@import 'src/styles/base/breakpoint';

.action-done-container {
  max-width: 600px;
  margin: auto;
  width: 100%;
  padding: 1.5rem;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  .action-done {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 3rem 1.2rem;
    color: white;
    background: $grey-linear-gradient-background;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.55);

    .result-title {
      font-size: 1.8rem;
      color: white;
      text-transform: uppercase;
    }
    .result-text {
      padding: 0.7rem 0;
    }
    .stars {
      display: flex;
      align-items: center;
      color: $blue-light;
      font-size: 1.5rem;
      font-weight: bold;
      span {
        margin-right: 0.5rem;
      }
    }
    button.btn-detail {
      max-width: 210px;
    }
  }
}
