@import '../base/color';
a {
  color: $gold-light;
  &:visited {
    color: $gold-light;
  }
}

a:focus {
  outline: none;
  box-shadow: inset 0 0 0 1px $grey-bright;
  > div:first-child {
    box-shadow: 0 0 0 1px $grey-bright;
  }
}
a.MuiLink-underlineHover:hover {
  text-decoration: none;
}
a.MuiTypography-colorPrimary {
  color: $grey-bright;
}
