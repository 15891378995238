@import 'src/styles/base/color';
@import 'src/styles/base/mixins';

@include checkBox();

.terms-wrapper {
  padding: 0rem 1.5rem 0 1.5rem;
  box-sizing: border-box;
  width: 100vw;
  overflow-x: hidden;
  p,
  ul,
  li,
  label,
  span {
    color: $grey-bright;
  }
  h1 {
    color: $gold-shadow;
    font-weight: 800;
  }
}
.terms-content {
  max-width: 45rem;
  margin: auto;
  .action {
    cursor: pointer;
    color: $gold-shadow;
  }
}
.dataShare-content-wrapper,
.dataShare-content-root {
  width: inherit;
}

.terms-footer {
  max-width: 45rem;
  width: 100%;
  margin: auto;
  height: 60px;
  text-align: center;
  padding-top: 1.5rem;
  button {
    margin: 0;
    max-width: 150px;
    &:disabled {
      opacity: 0.3;
    }
  }
}
