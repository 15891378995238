@import 'src/styles/base/breakpoint';
@import 'src/styles/base/color';

.em-header {
  color: $grey-bright;
  border-bottom: 1px solid rgba(163, 163, 163, 0.4);
  padding: 1em 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.em-root {
  overflow: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: $scrollbar-track;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $scrollbar-thumb;
  }
  .em-content {
    padding: 1.5rem 2.5rem;
    width: 100%;

    @media #{$large-phone} {
      width: 100%;
      padding: 2rem;
    }
    .em-content-box-img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .icon {
        margin-bottom: 1rem;
      }
    }
    .em-detail {
      display: flex;
      flex-direction: row;
      margin: 0.5em 0;
      .em-detail-efficiency {
        display: flex;
        flex: 1;
        align-self: flex-start;
        margin-top: 0.65rem;
        color: $soft-grey;
      }
      .em-picto-flow {
        display: flex;
        align-self: flex-end;
        .em-pic-content {
          margin: 0.3em;
        }
      }
    }
    .em-efficiency {
      margin: 0 0.5rem 0 0.25rem;
    }
    .em-title {
      margin-bottom: 0;
      text-align: center;
    }
    .long-name {
      font-weight: bold;
      line-height: 150%;
      background: radial-gradient(
        60.65% 30.62% at 50% 3.13%,
        #2a2b30 0%,
        #1b1c22 100%
      );
      border: 1px solid $blue;
      padding: 1.5rem 0.6rem;
      border-radius: 50px 1px 50px 1px;
      text-align: center;
      margin: 1.5rem 0 1rem;
    }

    button.btn-action-launch {
      background: $blue-radial-gradient;
      color: #1b1c22;
      border: none;
      border-radius: 2px;
      margin: 1.5rem 0 0;
      width: 100%;
      text-transform: none;
      cursor: pointer;
      padding: 0.6rem 0;
    }
  }
}
.em-content-box-text {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2.5rem;
  @media #{$large-phone} {
    padding: 1.5rem 1.5rem;
  }
  .em-description {
    padding-bottom: 2rem;
  }
  .em-description-2 {
    padding-top: 2rem;
  }
  .em-description-3 {
    padding-bottom: 0.5rem;
  }
  .em-title {
    margin-bottom: 0;
  }
}
.block {
  display: block !important;
}
.ecogesture-modal {
  button.btn-secondary-negative {
    margin: 1rem 0 1.5rem !important;
  }
}

#accessibility-title {
  display: none;
}
