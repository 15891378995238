@import 'src/styles/base/color';
@import 'src/styles/base/breakpoint';

.profile-navigation {
  border-top: 1px solid $grey-dark;
  background: $dark-2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
  min-width: 300px;

  @media (min-width: $width-tablet) {
    background: $dark-light-2;
    height: 3rem;
  }

  button.profile-navigation-button {
    max-width: 12.5rem;
    margin: 0;
    padding: 0.5rem 1rem;
  }
}
