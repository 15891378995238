/** BLACK **/
$default-background: #203864;

$dark: #182a4e;          
$dark-2: #142546;        
$dark-light: #1e3a5c;    
$dark-light-2: #0f203c;  
$dark-light-3: #26476e; 
$dark-3: #181819;
$dark-background: radial-gradient(
  60.65% 30.62% at 50% 3.13%,
  #2a2b30 0%,
  #1b1c22 100%
);
$bottom-bar-grey: #32343d;
$black-shadow: #0000008c;
/** RED **/
$red-primary: #d25959;

/** YELLOW **/
$gold: #b2901b;
$gold-40: rgba(227, 184, 42, 0.4);
$gold-light: #deaf0e;
$gold-shadow: #e3b82a;
$gold-euro: #f1c017;

/** ORANGE **/
$orange: #ec9d41;

/** BLUE **/
$blue: #58ffff;
$blue-40: #58ffff40;
$blue-light: #61f0f2;
$blue-shadowed: rgba(58, 152, 236, 0.4);
$blue-gradient: linear-gradient(180deg, #61f0f2 0%, #48c2c4 100%);
$blue-radial-gradient: radial-gradient(
    105.25% 64.58% at 49.68% 70.83%,
    rgba(1, 153, 163, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  ),
  $blue;
$blue-radial-gradient-transparent: radial-gradient(
  circle,
  $blue 0%,
  rgba(255, 255, 255, 0) 100%
);
$blue-grey: #bfcce4;

/** GREEN **/
$green: #7fd771;
$green-light: #a6e191;

/** WHITE **/
$white: #ffffff;
$white-light: rgba(255, 255, 255, 0.6);

/** GREY **/
$grey-bright: #e0e0e0;
$soft-grey: #a0a0a0;
$grey-dark: #7b7b7b;
$grey-linear-gradient-background: linear-gradient(
  180deg,
  $default-background 0%,
  $dark-2 100%
);
$grey-linear-gradient-background-hover: linear-gradient(
  180deg,
  $default-background 0%,
  $dark-light-3 100%
);
$grey-brighter-background-gradient: linear-gradient(
  180deg,
  rgb(70, 71, 77) 0%,
  rgb(57, 58, 63) 100%
);

/** App colors **/
$elec-color: #d87b39;
$elec-color-40: rgba(216, 123, 57, 0.4);
$elec-off-color: #5d3d2a;
$elec-compare-color: #e2bca1;
$elec-compare-off-color: #795c47;

$gas-color: #45d1b8;
$gas-color-40: rgba(69, 209, 184, 0.4);
$gas-off-color: #184940;
$gas-compare-color: #a8f7e9;
$gas-compare-off-color: #597773;

$water-color: #3a98ec;
$water-color-40: rgba(58, 152, 236, 0.4);
$water-off-color: #20415e;
$water-compare-color: #abd4fa;
$water-compare-off-color: #4d5c6e;

$multi-color: #e3b82a;
$multi-off-color: #705d1d;
$multi-compare-color: #ffd597;
$multi-compare-off-color: #7d6a4e;

/** TABS GRADIENT **/
$multi-color-radial-gradient-transparent: radial-gradient(
  circle,
  #e3b82a 0%,
  rgba(255, 255, 255, 0) 100%
);
$elec-color-radial-gradient-transparent: radial-gradient(
  circle,
  #d87b39 0%,
  rgba(255, 255, 255, 0) 100%
);
$water-color-radial-gradient-transparent: radial-gradient(
  circle,
  #3a98ec 0%,
  rgba(255, 255, 255, 0) 100%
);
$gas-color-radial-gradient-transparent: radial-gradient(
  circle,
  #45d1b8 0%,
  rgba(255, 255, 255, 0) 100%
);

$multi-color-radial-gradient: radial-gradient(
    105.25% 64.58% at 49.68% 70.83%,
    rgba(226, 137, 4, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  ),
  #f1c017;
$elec-color-radial-gradient: radial-gradient(
    105.25% 64.58% at 49.68% 70.83%,
    rgba(158, 67, 2, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  ),
  #d87b39;
$gas-color-radial-gradient: radial-gradient(
    105.25% 64.58% at 49.68% 70.83%,
    rgba(4, 106, 88, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  ),
  #45d1b8;
$water-color-radial-gradient: radial-gradient(
    105.25% 64.58% at 49.68% 70.83%,
    rgba(2, 93, 174, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  ),
  #3a98ec;

/** SCROLLBAR **/

$scrollbar-track: #3e4045;
$scrollbar-thumb: #6f7074;
