@import 'src/styles/base/color';
@import 'src/styles/base/breakpoint';

.duel-ongoing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.duel-title {
  color: $soft-grey;
  margin-top: 1rem;
}
.duel-goal {
  color: $grey-bright;
  margin: 1rem 3rem;
  text-align: center;
}
.duel-consumption {
  color: $grey-bright;
  margin: 1rem auto;
  @media (max-width: $width-phone) {
    margin: 0.5rem auto -0.5rem;
  }
  .consumption {
    color: $gold-light;
  }
}
.duel-chart {
  height: 15.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
}
.duel-chart-caption {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  .duel-caption {
    display: flex;
    margin-top: 0.75rem;
    .caption-icon {
      margin: auto 1.5rem;
    }
    .caption-label {
      color: $grey-bright;
    }
  }
}
