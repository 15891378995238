@import 'src/styles/base/typo-variables';
@import 'src/styles/base/color';

.cardContent {
  &.onGoing {
    border: 1px solid $grey-bright;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.titleBlock {
  width: fit-content;
  max-width: 235px;
}

.notifChallenge {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 4px;
  top: 4px;
  width: 1.25rem;
  height: 1.25rem;
  color: $dark-light;
  border-radius: 50%;
  border: 1px solid $dark-light;
  z-index: 1;
  background: $blue-radial-gradient;
  font-size: 12px;
}

.smallCard {
  background: $grey-linear-gradient-background;
  max-height: 90px;

  @media all and (max-height: 800px) {
    max-height: 85px;
  }
  @media all and (max-height: 730px) {
    max-height: 70px;
  }

  &.finished {
    border: 1px solid #7b7b7b;
    background: $dark-light-2;
    & > * {
      color: $grey-dark !important;
    }
  }

  &.duelCard {
    background: $dark-3;
    border: solid 1px rgba(97, 240, 242, 0.5);
    justify-content: space-between !important;
    &.active {
      background: $grey-linear-gradient-background;
    }
    .duelButton {
      width: 100%;
      justify-content: space-between !important;
    }
  }

  &.goDuel {
    background: $blue-gradient;
    justify-content: space-evenly !important;

    span {
      color: $dark-light-2 !important;
      justify-content: space-between !important;
      font-weight: 700;
    }
  }

  &.MuiButton-root {
    justify-content: start;
  }

  span.MuiButton-label {
    display: flex;
    justify-content: flex-start;
    padding: 1rem 0.5rem;
    color: $grey-bright;
    transition: all 300ms ease;
    text-transform: capitalize;

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-transform: capitalize;

      .stars {
        display: flex;
        gap: 2px;
      }
    }
    .spinner-container {
      height: 3.75rem;
      width: 3.75rem;
      margin: auto;
    }
    .cardIcon {
      margin-right: 1rem;
    }
    span {
      font-size: 1.1rem;
      font-weight: 700;
      @media all and (max-height: 700px) {
        font-size: 1rem;
      }
    }
    .challengeminIcon {
      filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.55));
    }

    .starCount {
      display: flex;
      align-items: center;
      margin: 0;
      span {
        margin: 0;
      }
      .blueNumber {
        font-weight: 900;
        color: $blue-light;
        margin: 0 0.3rem 0 0.7rem;
      }
    }
    .finalDuel {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .starCount {
        span {
          font-size: 1rem;
          font-weight: 500;
        }
        .blueNumber {
          margin-left: 0;
        }
      }
      &.result {
        span {
          margin-bottom: 0.2rem;
          font-weight: 600;
          font-size: 1rem;
        }
      }
    }
  }
}
