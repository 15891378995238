@import 'src/styles/base/color';
@import 'src/styles/base/breakpoint';
.splash-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  .splash-loader {
    width: 100%;
    margin-top: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo-wrap {
      img {
        width: 200px;
      }
    }

    .error {
      margin-bottom: 1rem;
    }

    span {
      color: $white;
      font-size: 3rem;
      margin: 0;
      letter-spacing: -0.02em;
      font-weight: 400;
    }
  }
  .splash-logos-container {
    margin-top: auto;
    padding: 1.5rem;
    max-width: 380px;
    img {
      width: 100%;
    }
  }
  .step-label {
    margin: 1rem 0;
    max-width: 350px;
    color: white;
    text-align: center;
    height: 40px;
  }
  .splash-error-text {
    text-align: center;
    color: $red-primary;
    margin: 0.5rem;
  }
}
.splash-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 10rem;

  button.btn-highlight {
    max-width: 230px;
    width: 100%;
    margin: 0.5rem 0 0;
    height: 40px;
  }
}
.splash-progress {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem auto;
  padding: 1rem;
  transition: all 300ms ease;
  border-radius: 12px;
  box-sizing: border-box;
  max-width: 350px;

  .splash-progress-bar-container {
    flex: 1;
    height: 12px;
    box-sizing: border-box;
    background-image: linear-gradient(
      45deg,
      #e3b82a 11.11%,
      #1b1c22 11.11%,
      #1b1c22 50%,
      #e3b82a 50%,
      #e3b82a 61.11%,
      #1b1c22 61.11%,
      #1b1c22 100%
    );
    background-size: 9px 9px;
    border: solid 1px $gold-shadow;
    border-radius: 12px;
    .splash-progress-bar-content {
      height: 12px;
      box-sizing: border-box;
      background-color: $gold-shadow;
      border: solid 1px $gold-shadow;
      border-radius: 12px 4px 0 12px;
      border-right: none;
      transition: all 300ms ease;
      position: relative;
      overflow: hidden;
      top: -1px;
      left: -1px;
      &:after {
        content: '';
        position: absolute;
        display: block;
        transform: rotate(45deg);
        background-color: $dark-2;
        width: 15px;
        height: 15px;
        right: -9px;
        top: -8px;
      }
    }
  }
}
