@import 'src/styles/base/color';

.slide {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.55);
  border-radius: 4px;
  transition: all 300ms ease;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  &.active {
    transform: scale(1);
  }
  &.inactive {
    transform: scale(0.9);
  }
  .cardContent {
    background: $grey-linear-gradient-background;
    width: 100%;
    height: inherit;
    box-sizing: border-box;
    padding: 1rem;
    transition: all 300ms ease;
    border-radius: 4px;
  }
}
