@import 'src/styles/base/color';

.card-title-wrapper {
  display: flex;
  justify-content: space-between;
}

.notif-card-wrapper {
  .notif-card {
    background-color: $dark-2;
  }
}
