@import 'src/styles/base/color';
@import 'src/styles/base/breakpoint';

.ecogesture-profile-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;

  // 100 -header -navigation -bottom -navbar
  height: calc(100vh - 60px - 72px - 56px);
  @media (min-width: $width-large-phone) {
    // 100 -cozyBar -header -navigation -bottom -navbar
    height: calc(100vh - 48px - 148px - 72px - 0px);
  }
  .ecogesture-form-single {
    max-width: 53rem;
    color: $white;
    margin: 1rem 1rem 3.5rem;
    @media (min-width: $width-tablet) {
      margin: 1rem 1rem 0;
    }
    label {
      cursor: pointer;
    }
  }
}
