@import 'src/styles/base/color';
@import 'src/styles/base/breakpoint';

.eco-gagnant-program-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .title-wrapper {
    padding: 15px;
    text-align: center;
    max-width: 400px;
    h1 {
      color: $white;
      font-size: 1.2em;
    }

    .separator {
      height: 1px;
      background-color: $white;
      margin: 20px 0;
      width: 50%;
      margin: auto;
    }

    h2 {
      color: $white;
      font-size: 0.9em;
    }
  }

  .buttons-wrapper {
    max-width: 360px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;

    .button {
      text-decoration: none;
      cursor: pointer;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.55);
      width: 130px;
      height: 150px;
      border: 2px dashed $white;
      border-radius: 4px;
      background: $grey-linear-gradient-background;
      &:hover {
        background: $grey-linear-gradient-background-hover;
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      img {
        width: 70%;
        height: 60%;
      }

      span {
        color: $white;
        font-weight: 600;
      }
    }
  }

  @media (min-width: $width-tablet) {
    .buttons-wrapper {
        max-width: 560px;
        gap: 25px;
    
        .button {
          width: 180px;
          height: 200px;
    
          img {
            width: 70%;
            height: 60%;
          }
    
          span {
            margin-top: 5px;
            font-size: 1.1em;
          }
        }
      }
  }

  .helper-text {
    color: $white;
    font-size: 0.8em;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
