@import 'src/styles/base/color';

#new-notif-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;

  #please-connect-message {
    margin-top: 50px;
    color: #e0e0e0;
    max-width: 250px;
    text-align: center;
  }

  form {
    max-width: 650px;

    .conso-helper {
      text-align: right;
    }

    .step {
      width: 100%;
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.duration > div:first-of-type {
        margin-right: 10px;
      }

      label {
        color: $white;
        margin-right: 20px;
        min-width: 80px;
      }
    }

    .step.button {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      label {
        max-width: 500px;
        text-align: center;
        margin-right: 0;
        margin-bottom: 15px;
      }
    }

    .error {
      margin-top: 10px;
      color: $red-primary;
      text-align: center;
    }
  }
}
