@import 'src/styles/base/color';
@import 'src/styles/base/breakpoint';

.legal-notice-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $white;
  padding: 1.5rem 1.5rem 0;
  
  .legal-notice-content {
    font-size: 0.9em;
    p {
      color: $white;
    }

    ol {
      padding-left: 0;
      & > li {
        color: $gold-shadow;
      }
    }

    a {
      color: $gold-shadow;
      text-decoration: none;
    }

    li {
      margin: 1rem 0;
      color: $white;
    }
    
    .version {
      color: $soft-grey;
      text-align: center;
      margin-top: 0;
      margin-bottom: 2rem;
    }
  
    max-width: 45.75rem;
    @media #{$large-phone} {
      width: 100%;
    }
  }
}
