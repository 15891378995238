@import 'color';
@import 'breakpoint';
@import 'z-index';

html {
  background: $dark-light-2;
}

body {
  background: $dark-light-2;
  overflow: unset !important;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.cozy-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0;
}

[role='banner'] .coz-bar-container {
  background-color: $white;
  @media #{$large-phone} {
    padding: 0.6rem 0 0 0;
    background-color: unset;
  }
}

.coz-bar-wrapper {
  box-shadow: unset !important;
  background: unset !important;
}
.coz-label {
  color: #32363f;
}

.content-view-loading {
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $grey-bright;
  .content-view-loading-text {
    padding-top: 1rem;
    margin: 0 2rem;
    text-align: center;
  }
  .content-view-loading-button {
    max-width: 50vw;
    margin-top: 1rem;
  }
}

[role='main'] {
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: $scrollbar-track;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $scrollbar-thumb;
  }
}
