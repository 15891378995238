@import 'src/styles/base/color';

.modal-overlay {
  width: 100%;
  height: 100%;
}

.cardUnlocked {
  background: linear-gradient(180deg, #323339 0%, $dark-light 100%);
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.55));

  button.btn-duel-active {
    padding: 1.2rem 1.5rem;
  }
  .challengeIcon {
    @media all and (max-height: 700px) {
      width: 65%;
      margin: auto;
    }
  }
}
