@import 'src/styles/base/color';

.loader {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &.gold {
    color: $gold;
  }

  &.gaz {
    color: $gas-color;
  }

  &.elec {
    color: $elec-color;
  }

  &.water {
    color: $water-color;
  }

  &.black {
    color: $dark;
  }

  .bars {
    height: 50px;
    margin: auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 8px;

    .bar {
      width: 10px;
      border-radius: 5px;
      background: currentColor;
      // Negative delay to fix bar appearing after delay
      animation: load 0.4s -0.4s linear infinite alternate;
      &:nth-child(1) {
        animation-delay: -0.1s;
      }
      &:nth-child(3) {
        animation-delay: -0.55s;
      }
    }

    @keyframes load {
      0% {
        height: 20%;
      }
      100% {
        height: 100%;
      }
    }
  }
}
