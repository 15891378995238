@import '../base/color';

div.expansion-panel-root {
  margin: 1.2rem 0;
  color: $grey-bright;
  background: $grey-linear-gradient-background;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.75);
  transition: background-color 0.6s ease;
  box-sizing: border-box;
  &.Mui-expanded:first-child {
    margin: 1.2rem 0;
  }
  &.Mui-expanded:last-child {
    margin: 1.2rem 0;
  }
  &.red-border {
    border: 1px solid $red-primary;
  }
}
div.expansion-panel-summary {
  padding: 0.25rem 1.2rem;
  min-height: 4rem;
  &.Mui-focused {
    border-radius: 4px;
    background-color: unset;
    box-shadow: 0 0 0 1px $grey-bright;
  }
  &.Mui-expanded {
    min-height: 4rem;
    &.small {
      min-height: 3rem;
    }
  }
  &.small {
    padding: 0 1.2rem;
    min-height: 3rem;
  }
  &.bold-text {
    font-weight: bold;
  }
}
div.expansion-panel-content {
  display: flex;
  align-items: center;
  &.Mui-expanded {
    margin: 0.75rem 0;
  }
}
div.expansion-panel-details {
  padding: 0 1.2rem 1.2rem;
}
