@import 'src/styles/base/color';

.profile-type-progress {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .profile-type-progress-label {
    font-size: 0.938rem;
    font-weight: 700;
    width: 1.875rem;
    color: $gold-shadow;
    text-align: right;
  }
  .profile-type-progress-bar-container {
    margin-left: 0.5rem;
    flex: 1;
    height: 6px;
    background-color: $dark-light-2;
    .profile-type-progress-bar-content {
      height: 100%;
      background-color: $gold-shadow;
      border-radius: 12px;
    }
  }
}
