@import 'src/styles/base/color';

.duel-unlocked-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
}
.duel-icon {
  margin-top: 2.5rem;
}
.duel-title {
  color: $soft-grey;
}
.duel-description {
  color: $blue-light;
  margin-top: 0.75rem;
  text-align: center;
}
.duel-average-info {
  color: $grey-bright;
  margin-top: 2.5rem;
  text-align: center;
}
.button-start {
  margin-top: 1rem;
  width: 100%;
  max-width: 175px;
}
button.btn-secondary-negative {
  margin: 0;
  padding: 0.5rem;
}
