@import 'src/styles/base/color';

.duel-result-modal-root {
  text-align: center;
  overflow-x: hidden;
  .title {
    margin: 2rem 0 1rem;
    color: $grey-bright;
  }
}

#accessibility-title {
  display: none;
}

.imgResultContainer {
  position: relative;
  height: 300px;
  width: 100%;
  .challengeWon {
    position: absolute;
    transform: translateX(-50%);
  }

  .imgResult {
    position: absolute;
    transform: translate(-50%, 32%);
  }
}

.buttonCloseModal {
  margin-top: 1rem !important;
}
